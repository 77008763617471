<template>
  <div class="manage-page">
    <HeaderTab :tabs="tabs" @click="handleTabClick" :value="currentTab" >
      <!-- <img :src="AddIcon" class="add-icon" @click="handleAddBill" /> -->
    </HeaderTab>
    <div class="search-container">
      <div class="search-wrap">
        <Search v-model="searchTotal" @search="resetList" />
      </div>
      <img :src="RevertIcon" class="revert-icon" @click="showSortDialog" />
    </div>
    <div class="card-list-container">
      <Card class="card" v-for="(item, index) in cardList" :key="index" :content="item">
        <div class="btn-wrap">
          <CustomButton class="mr-12" size="mini" @click="handleAddBill(index)">添加账款信息</CustomButton>
          <CustomButton size="mini-plain" @click="handleBillManage(index)">账款管理</CustomButton>
        </div>
      </Card>
    </div>
    <InfiniteLoading ref="infinite" @infinite="infiniteHandler">
      <span slot="no-more">
        <!-- 暂无更多 -->
      </span>
    </InfiniteLoading>
    <SortDialog
      v-model="sortValue"
      ref="sort"
      :sortData="sortData"
      @confirm="resetList"
    />
  </div>
</template>

<script>
import HeaderTab from '@/components/header/headerTabs/index.vue'
import AddIcon from '@/assets/images/common/add.png'
import RevertIcon from '@/assets/images/common/sort.png'
import Search from '@/components/common/search/index.vue'
import Card from '@/components/common/card/index.vue'
import CustomButton from '@/components/common/button/CustomButton.vue'
import InfiniteLoading from 'vue-infinite-loading'
import { fetchTerminalList, fetchGrowerBillManageList } from '@/api/bill'
import SortDialog from "@/components/dialog/SortDialog.vue"
import { getDealerId } from '@/utils/user'


export default {
  components: {
    HeaderTab,
    Search,
    Card,
    CustomButton,
    InfiniteLoading,
    SortDialog
  },
  data() {
    return {
      AddIcon,
      RevertIcon,
      currentTab: '终端网点',
      tabs: [
        {title: '终端网点'},
        {title: '种植大户'}
      ],
      cardConfig: {
        terminal: [
          {
            label: '联系人',
            field: 'contactPerson'
          },
          {
            label: '所属市县',
            field: 'address'
          },
          {
            label: '联系电话',
            field: 'contactTel',
            color: '#006EFB'
          },
          {
            label: '账款金额',
            field: 'fundPool'
          },
        ],
        grower: [
          {
            label: '创建人',
            field: 'createName'
          },
          {
            label: '账款日期',
            field: 'fundDate'
          },
          {
            label: '账款金额',
            field: 'fundNum'
          },
        ],
      },
      
      list: [],
      pageNo: 1,
      pageSize: 5,
      total: 0,
      searchTotal: '',
      sortValue: '',
      id: '',
      sortData: [
        {
          label: "账款",
          names: ["fundPool", "fundPool desc"],
        }
      ]
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.name === 'BillManage' && ['BillTerminalAdd', 'BillBigGrowersAdd', 'BillTerminalManage'].indexOf(from.name) === -1) { 
      window.__billManage_refresh__ = true;
    } else {
      window.__billManage_refresh__ = false;
    }
    next();
  },
  computed: {
    cardList() {
      let ret = []
      this.list.map && this.list.map(item=>{
        let temp = []
        let type = this.currentTab === '终端网点' ? 'terminal' : 'grower'
        this.cardConfig[type].map(val=>{
          temp.push({
            label: val.label,
            color: val.color,
            value: item[val.field]
          })
        })
        ret.push(temp)
      })
      return ret
    }
  },
  mounted() {
    if (this.$route.query.type === 'bigGrowers') {
      this.currentTab = '种植大户'
    }
    else {
      this.currentTab = '终端网点'
    }
    this.id = this.$route.query.id
  },
  activated() {
    if (window.__billManage_refresh__) {
      this.resetList()
    }
  },
  methods: {
    showSortDialog() {
      this.$refs.sort.showDialog();
    },

    handleTabClick(tab) {
      this.currentTab = tab
      this.searchTotal = ''
      this.sortValue = ''
      this.resetList()
    },

    async infiniteHandler($state) {
      await this.fetchList();
      $state.loaded();
      this.pageNo += 1;
      if (this.total - this.list.length === 0) {
        $state.complete();
      }
    },
    resetList() {
      this.$refs.infinite._events["$InfiniteLoading:reset"][0]();
      this.list = [];
      this.pageNo = 1;
      this.total = 0;
    },

    async fetchList() {
      let response = []
      let data = {
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
        "page.orderBy": this.sortValue || "",
        "dealerId": getDealerId(),
        "searchTotal": this.searchTotal
      }
      if (this.currentTab === '终端网点') {
        data.terminalId = this.id
        data.terminalType = '1'
        response = await fetchTerminalList(data)
      }
      else if(this.currentTab === '种植大户') {
        data.bigfarmersId = this.id
        data.bigfarmersType = '1'
        response = await fetchGrowerBillManageList(data)
      }
      this.list = this.list.concat(response.data || []);
      this.total = (response.page && response.page.total) || 0;
    },
    
    handleAddBill(index) {
      if (this.currentTab === '终端网点'){
        this.$router.push(
          {
          name: 'BillTerminalAdd',
          query: {
            terminalId: this.list[index].terminalId,
            terminalType: this.list[index].terminalType || 1,
            contactPerson: this.list[index].contactPerson || '',
            contactTel: this.list[index].contactTel || '',
            address: this.list[index].address || '',
          }
        })
      }
      else if(this.currentTab === '种植大户') {
        this.$router.push({
          name: 'BillBigGrowersAdd',
          query: {
            bigfarmersId: this.list[index].bigfarmersId,
            bigfarmersType: this.list[index].bigfarmersType || 1,
            contactPerson: this.list[index].contactPerson || '',
            contactTel: this.list[index].contactTel || '',
            address: this.list[index].address || '',
          }
        })
      }
    },

    handleBillManage(index) {
      let path = ''
      if (this.currentTab === '终端网点') {
        const contactPerson = this.list[index].contactPerson || '';
        const contactTel = this.list[index].contactTel || '';
        const address = this.list[index].address || '';
        path = `/bill/terminalManage/1/${this.list[index].terminalId}?contactPerson=${contactPerson}&contactTel=${contactTel}&address=${address}`
      }
      else {
        const contactPerson = this.list[index].contactPerson || '';
        const contactTel = this.list[index].contactTel || '';
        const address = this.list[index].address || '';
        path = `/bill/bigGrowersManage/1/${this.list[index].bigfarmersId}?contactPerson=${contactPerson}&contactTel=${contactTel}&address=${address}`
      }
      setTimeout(()=>{
        this.$router.push({
          path
        })
      }, 300)
    }
  }
}
</script>

<style lang="scss" scoped>
.manage-page {
  width: 100%;
  min-height: 100vh;
  background: #FAFBFC;
  .add-icon {
    position: absolute;
    width: 15px;
    height: 15px;
    top: 15px;
    right: 24px;
  }
  .mr-12 {
    margin-right: 12px;
  }
  .search-container {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 0px 24px 0px 12px;
    background: white;
    .search-wrap { 
      width: 295px;
    }
    .revert-icon {
      width: 12px;
    }
  }
  .card-list-container {
    width: 100%;
    box-sizing: border-box;
    padding: 12px 12px 0;
    .card:not(:last-child) {
      margin-bottom: 12px;
    }
    .card {
      .btn-wrap {
        text-align: right;
      }
    }
  }
}
</style>