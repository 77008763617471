import { post } from '@/utils/request';

export const fetchTerminalBillManageList = async data => {
  return await post('/appdealer/api/terminal/fund/list', data)
}

export const fetchGrowerBillManageList = async data => {
  return await post('/appdealer/api/bigfarmers/fund/list', data)
}

export const submitBill = async data => {
  return await post('/appdealer/api/terminal/fund/save', data)
}

export const submitBigGrowerBill = async data =>{
  return await post('/appdealer/api/bigfarmers/fund/save', data)
}

export const fetchTerminalList = async data => {
  return await post('/appdealer/api/terminal/fund/terminallist', data)
}

export const fetchGrowersList = async data => {
  return await post('/appdealer/api/bigfarmers/get_bigFarmersList', data)
}